<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body pad-lr-0 ov-h">
            <div class="header_section d-block padd-30 pt-0 pb-0">
              <h4 class="menu_header">Bulk Generate</h4>
              <div class="back-btn">
                <router-link :to="{ name: 'giftcards'}"><i class="fa fa-arrow-left"></i></router-link>
              </div>
              <hr>
            </div>
            <div class="create_items_section">
              <form @submit.prevent="bulkGenerate()">
                <div class="row">
                  <div class="col-lg-6 pad-lr-0">
                    <div class="pl-5 pr-4">
                      <div class="setting_content">
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret">Select Voucher:</label>
                            <div class="col-sm-7">
                              <div>
                                <select class="form-control" v-model="voucherType" @blur="v$.voucherType.$touch">
                                  <option value="">Select a GiftCard Type</option>
                                  <option v-for="(voucher, vindex) in vouchers" :key="vindex" :value="voucher">{{voucher.voucher_name}}</option>
                                </select>
                              </div>
                              <div class="col-sm-7 input_error" v-if="v$.voucherType.$error"> Please select Gift card type.</div>

                           
                            </div>
                          </div>
                        </div>
                        <transition name="fade">
                          <div v-if="voucherType.allow_custom_amount && !voucherType.allow_multiple_amount">
                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5" for="dm_consumer_secret">Amount:</label>
                                <div class="col-sm-7">
                                  <input class="form-control" v-model="customAmount"/>
                                </div>
                              </div>
                            </div>
                          </div>
                        
                        </transition>
                        <transition name="fade">
                          <div v-if="voucherType.allow_multiple_amount">
                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5" for="dm_consumer_secret">Amount:</label>
                                <div class="col-sm-7">
                                  <select class="form-control" id="" v-model="customAmount">
                                    <option value="">-- select amount --</option>
                                    <option v-for="amount in voucherType.voucher_amounts" :key="amount" :value="amount" >{{ amount }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        
                        </transition>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="email"> How many:</label>
                            <div class="col-sm-7">
                              <div>
                                <input type="number" class="form-control" @blur="v$.quantity.$touch"  v-model="quantity">
                              </div>
                              <div class="col-sm-7 input_error" v-if="v$.quantity.$error">
                                <span v-if="v$.quantity.integer.$invalid">Quantity must be a number</span>
                                <span v-if="v$.quantity.required.$invalid">Quantity is required</span>
                               
                              </div>
                            </div>
                           
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="email">From:</label>
                            <div class="col-sm-7">
                              <div>
                                <input type="text" class="form-control" v-model="from">
                              </div>
                              
                            </div>
                           
                          </div>
                        </div>

                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="email">Payment Details:</label>
                            <div class="col-sm-7">
                              <div>
                                <select class="form-control" id="" v-model="paymentInfo">
                                  <option value="">--Select Payment Method--</option>
                                  <option value="credit card">Credit Card</option>
                                  <option value="mpesa">Mpesa</option>
                                  <option value="cash">Cash</option>
                                  <option value="bank transfer">Bank Transfer</option>
                                  <option value="other">Other</option>
                                  <option value="momo">MoMo</option>
                                  <option value="airtel">Airtel</option>
                                </select>
                              </div>
                              <div v-if="paymentInfo == 'other'" class="mt-2">
                                <input type="text" class="form-control" v-model="otherPayment" placeholder="specify other payment details">
                              </div>
                             
                            </div>
                           
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret">Payment Status:</label>
                            <div class="col-sm-7">
                              <label class="radio-inline"><input type="radio" v-model="paid" :value="1">&nbsp; Paid </label> &nbsp;&nbsp;
                              <label class="radio-inline"><input type="radio" v-model="paid" :value="0"> &nbsp; Unpaid</label>
                            </div>
                          </div>
                        </div>
                        <!--  -->
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret"> Optional Special message:</label>
                            <div class="col-sm-7">
                              <textarea v-model="message" class="form-control" rows="3"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <!--  -->
                </div>
                <div class="row bt-b">
                  <div class="col-md-12">
                    <div :class="['form-group ml-2r mt-3', {'disabled_content': user.role.id == 3}]">
                      <button type="submit" class="btn btn-orange"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Generate</span></button>
                    </div>
                  </div>
                </div>
              <!-- end row -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, integer } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();
export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            voucherType: '',
            message: '',
            quantity: 1,
            errors: [],
            spinner: false,
            customAmount: "",
            paymentInfo:'',
            from:'',
            otherPayment:'',
            vouchers: [],
            paid:0,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes..."

        }
    },
    mounted: async function () {
        this.getVoucherTypes();
        this.from = this.appConfigs.site_name !==''? this.appConfigs.site_name : 'PESAPAL'
    },
    validations() {
        return {
            voucherType: { required },
            quantity: {required,integer}
        }
    },
    watch:{
      voucherType(){
        if(this.voucherType.allow_custom_amount && !this.voucherType.allow_multiple_amount){
          this.customAmount = this.voucherType.voucher_amount
        }
        // 
      }
    },

    methods: {
      
        async bulkGenerate() {
          try {
            this.spinner = true;
            const isFormCorrect = this.v$.$validate()
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            this.errors = [];
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/bulk-generate";
            const paymentDetails = this.paymentInfo == 'other' ? this.otherPayment : this.paymentInfo
            const fromName = this.merchant.name ? this.merchant.name : 'PESAPAL'
            const payload = {
                'merchant_id': this.merchantID,
                'quantity': this.quantity,
                'from': this.from !='' ? this.from : fromName,
                'from_email': this.appConfigs.from_email !='' ? this.appConfigs.from_email : '',
                'message': this.message,
                'voucher_id' : this.voucherType.id,
                'amount':this.customAmount,
                'external_id': this.voucherType.external_voucher_id,
                'payment_method': paymentDetails,
                'payment_status': this.paid
            }
            const response = await this.axios.post(url,payload,config)
            if(response.status === 201){
              toast.success('bulk generation  was successfully');
              this.spinner = false;
                    this.$router.push({
                          name: 'giftcards',
                    });
                    
              }
        
          } catch (error) {
            this.spinner = false
            if(error.response.status === 400){
                  this.errors = error.response.data
                  let errorms = '';
                  for(let x=0;x< error.response.data.errors.length;x++){
                    errorms = error.response.data.errors[x] + ','
                  }
                  toast.error(errorms);
              }else if(error.response.status === 401){
                  this.$router.push({
                      name: 'login'
                  });
              }
            
          }
           

        },
        async getVoucherTypes() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/vouchers/${this.merchantID}`
            url = new URL(url);
            url.searchParams.set('status',1);
            this.axios.get(url, config).then((response) => {
                if (response.status === 200 ) {
                  this.vouchers = response.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
            });
        },
    },
    mixins: [ReusableDataMixins]
}
</script>
